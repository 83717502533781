import React from 'react';
import Image from '../Image';
import Text from '../Typography';
import Button from '../Button';
import { Section, Wrapper, Container } from './styles/index.styled';

const Hero = ({ data }) => (
	<Section id={data?.id}>
		<Wrapper>
			<Container style={{ maxWidth: '750px' }}>
				<Text Element="h1" text={data?.heading} classes="w-full mb-2" />
				<Text Element="h5" text={data?.headingKicker} classes="w-full mb-2" />
				<Text Element="div" html={data?.subhead} />
				<div className="flex items-center justify-start w-full mt-6">
					<Button
						Element={data?.primaryButton?.buttonType}
						to={
							data?.primaryButton?.download
								? data?.primaryButton?.asset?.url
								: data?.primaryButton?.to
						}
						text={data?.primaryButton?.label}
						variant={data?.primaryButton?.variant}
						classes="mr-3"
						target="_blank"
					/>
					<Button
						Element={data?.secondaryButton?.buttonType}
						to={
							data?.secondaryButton?.download
								? data?.secondaryButton?.asset?.url
								: data?.secondaryButton?.to
						}
						text={data?.secondaryButton?.label}
						variant={data?.secondaryButton?.variant}
						classes="mr-3"
						target="_blank"
					/>
				</div>
			</Container>
			<Container style={{ maxWidth: '800px' }}>
				<Image
					src={data?.image?.image?.gatsbyImageData}
					alt="graphic of a software developer"
					width="750px"
					height="750px"
					className="object-contain"
					objectFit="contain"
				/>
			</Container>
		</Wrapper>
	</Section>
);

export default Hero;
