import styled from 'styled-components';

export const Section = styled.section`
	width: 100vw;
	max-width: 100%;
	background-color: rgb(243 244 246);
	padding: 128px 0px 96px 0px;
	display: flex;
	align-items: center;
	@media (max-width: 1200px) {
		padding: 96px 0px 72px;
	}
	@media (max-width: 768px) {
		padding: 48px 0px 48px;
	}
`;
export const Wrapper = styled.div`
	width: 100%;
	max-width: 1782px;
	height: auto;
	padding: 0px 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 768px) {
		flex-direction: column;
		padding: 0px 16px;
	}
`;
export const Container = styled.div`
	width: 50%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	@media (max-width: 768px) {
		width: 100%;
		margin-bottom: 48px;
	}
`;
